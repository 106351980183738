<template>
  <form>
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Title</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                {{ form.title }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Description</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                {{ form.description }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Date</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                {{ form.date }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="form.accounts"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:item.no="{ index }">
                {{ index + 1 }}
              </template>
              <template v-slot:item.amount="{ item }">
                {{ formatPrice(item.amount) }}
              </template>
              <template v-slot:item.rate="{ item }">
                {{ formatPrice(item.rate) }}
              </template>
              <template v-slot:item.amountIDR="{ item }">
                {{ formatPrice(item.amountIDR) }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="12" md="6" xl="2">
            <v-card outlined>
              <v-card-title class="py-0">Total Debit</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(totalDebit) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6" xl="2">
            <v-card outlined>
              <v-card-title class="py-0">Total Credit</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(totalCredit) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </form>
</template>

<script>
export default {
  name: "detail-journal",
  props: {
    form: Object,
    id: Number,
  },
  computed: {
    totalDebit() {
      let total = 0;
      if (this.form.accounts && this.form.accounts.length > 0) {
        this.form.accounts.forEach(x => {
          if (x.type === "Debit" && !x.deleted) {
            total += x.amountIDR;
          }
        });
      }
      return total.toFixed(2);
    },
    totalCredit() {
      let total = 0;
      if (this.form.accounts && this.form.accounts.length > 0) {
        this.form.accounts.forEach(x => {
          if (x.type === "Credit" && !x.deleted) {
            total += x.amountIDR;
          }
        });
      }
      return total.toFixed(2);
    },
  },
  data: () => ({
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Account #",
        value: "accountNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Account Name",
        value: "accountName",
        sortable: false,
        divider: true,
      },
      {
        text: "D/C",
        value: "type",
        sortable: false,
        divider: true,
      },
      {
        text: "Currency",
        value: "currency",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "amount",
        sortable: false,
        divider: true,
      },
      {
        text: "Rate",
        value: "rate",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount IDR",
        value: "amountIDR",
        sortable: false,
        divider: true,
      },
      {
        text: "Reference",
        value: "reference",
        sortable: false,
        divider: true,
      },
      {
        text: "Remark",
        value: "remark",
        sortable: false,
        divider: true,
      },
    ],
  }),
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
