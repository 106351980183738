<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Title</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                {{ form.title }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Description</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                {{ form.description }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="4">
            <v-autocomplete
              outlined
              flat
              dense
              background-color="white"
              append-icon="mdi-magnify"
              :items="accounts"
              item-text="accountName"
              return-object
              v-model="account"
              :filter="filterObject"
              label="Chart Of Account"
            >
              <template v-slot:item="data">
                <v-list-item v-on="data.on" @click.stop="addAccount(data.item)">
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.accountName"></v-list-item-title>
                    <v-list-item-subtitle v-html="data.item.accountNumber"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="py-0">
            <v-data-table
              :headers="headers"
              :items="form.accounts"
              disable-pagination
              hide-default-footer
              @click:row="onClickRow"
            >
              <template v-slot:item.no="{ item }">
                {{ form.accounts.map(x => x).indexOf(item) + 1 }}
              </template>
              <template v-slot:item.amount="{ item }">
                {{ formatPrice(item.amount) }}
              </template>
              <template v-slot:item.rate="{ item }">
                {{ formatPrice(item.rate) }}
              </template>
              <template v-slot:item.amountIDR="{ item }">
                {{ formatPrice(item.amountIDR) }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="12" md="6" xl="2">
            <v-card outlined>
              <v-card-title class="py-0">Total Debit</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(totalDebit) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6" xl="2">
            <v-card outlined>
              <v-card-title class="py-0">Total Credit</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(totalCredit) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-journal
      :item="item"
      :index="index"
      :dialog="dialog"
      :accounts="accounts"
      @close="close"
      @save="addItem"
      @deleteItem="deleteItem"
    ></dialog-journal>
  </v-form>
</template>

<script>
import DialogJournal from "@/components/DialogJournal.vue";

export default {
  name: "form-journal",
  components: {
    DialogJournal,
  },
  props: {
    form: Object,
    accounts: Array,
  },
  computed: {
    totalDebit() {
      let total = 0;
      if (this.form.accounts && this.form.accounts.length > 0) {
        this.form.accounts.forEach(x => {
          if (x.type === "Debit" && !x.deleted) {
            total += x.amountIDR;
          }
        });
      }
      return total.toFixed(2);
    },
    totalCredit() {
      let total = 0;
      if (this.form.accounts && this.form.accounts.length > 0) {
        this.form.accounts.forEach(x => {
          if (x.type === "Credit" && !x.deleted) {
            total += x.amountIDR;
          }
        });
      }
      return total.toFixed(2);
    },
  },
  data: () => ({
    valid: true,
    dialog: false,
    item: {},
    index: -1,
    account: null,
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Account #",
        value: "accountNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Account Name",
        value: "accountName",
        sortable: false,
        divider: true,
      },
      {
        text: "D/C",
        value: "type",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "amount",
        sortable: false,
        divider: true,
      },
      {
        text: "Rate",
        value: "rate",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount IDR",
        value: "amountIDR",
        sortable: false,
        divider: true,
      },
      {
        text: "Reference",
        value: "reference",
        sortable: false,
        divider: true,
      },
      {
        text: "Remark",
        value: "remark",
        sortable: false,
        divider: true,
      },
    ],
  }),
  methods: {
    async update() {
      if (this.$refs.form.validate() && this.form.accounts.length > 0) {
        if (this.totalDebit - this.totalCredit === 0) {
          await this.$store.dispatch("journal/update", this.form);
        } else {
          this.$store.dispatch("toast", { type: "error", message: "Journal not balance" });
        }
      } else {
        this.$store.dispatch("toast", { type: "error", message: "Accounts is required" });
      }
    },
    deleteItem(data) {
      if (data.index > -1 && !data.item.id) {
        this.form.accounts.splice(data.index, 1);
        this.index = -1;
        this.dialog = false;
      }
    },
    addItem(item) {
      if (this.index > -1) {
        this.form.accounts[this.index] = item;
        this.index = -1;
      } else {
        this.form.accounts.push({ ...item });
      }
    },
    close() {
      this.index = -1;
      this.dialog = false;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    onClickRow(item) {
      this.item = item;
      this.index = this.form.accounts.findIndex(x => x === item);
      this.dialog = true;
    },
    addAccount(item) {
      item.reference = this.form.accounts[0].reference;
      item.remark = this.form.accounts[0].remark;
      this.item = item;
      this.dialog = true;
    },
    // eslint-disable-next-line no-unused-vars
    filterObject(item, queryText, itemText) {
      return (
        item.accountName.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.accountNumber.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
  },
};
</script>

<style></style>
